'use client';

import { forwardRef } from 'react';
import NextLink from 'next/link';
import useSpinner from '@/hooks/useSpinner';
import { shouldTriggerStartEvent } from '@/lib/router-events/patch-router/shouldTriggerStartEvent';

/**
 * Simple wrapper over <Link/> from next js with spinner on click
 */
export const Link = forwardRef<HTMLAnchorElement, React.ComponentProps<'a'> & { prefetch?: boolean }>(
  ({ href, onClick, prefetch = true, ...rest }, ref) => {
    const { setShowSpinner } = useSpinner();

    return (
      <NextLink
        href={href as string}
        onClick={event => {
          if (shouldTriggerStartEvent(href as string, event)) {
            setShowSpinner(true);
          }
          if (onClick) {
            onClick(event);
          }
        }}
        {...rest}
        ref={ref}
        prefetch={prefetch}
      />
    );
  }
);
